import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import consts from '../../pages/Auth/keys/const'
import styles from '../../assets/css/Registration.module.css';
import styles2 from '../../assets/css/style.module.css' 
import styles3 from '../../assets/css/Activate.module.css'
import { faEye, faSpinner, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import './MultiStepProgressBar.css'
import { connect } from "react-redux";
import { useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import DragandDrop from "../Drag-and-Drop/DragandDrop";
import { FiAlertTriangle } from "react-icons/fi";
import JSEncrypt from "jsencrypt";
import { postpersonal } from "../../Redux/SignUp/PersonalAction";
import Errormodal from "../modal/Errormodal";
import LottieAnimation from '../../Lotties';
import loader from '../../assets/loading.json'
import { Link } from "react-router-dom";
// import { postpersonal } from '../../Redux/Activate/PersonalAction';
// import Errormodal from "../Modal/Errormodal";
const Personal = ({next, personal, error, loading}) => {
    const [nameState, setNameState] = useState({});
    const [discription, setdiscription] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [convertedNumber, setConvertedNumber] = useState('');
    const [email, setemail] = useState("")
    const [address, setaddress] = useState("")
    const [state, setstate] = useState("")
    const [password, setPassword] = useState("")
    const [errorHandler, setErrorHandler] = useState([false, ""]);
    const [showerror, setshowerror] = useState(false)
    const handleDescription = (e) => {
        const value = e.target.value;
        setdiscription(value);
        setNameState({ ...nameState, ...{ discription } });
    };
    const handleNumber = (e) => {
        const value = e.target.value;
        setphoneNumber(value);
        if (phoneNumber.length > 0) {
            const converted = `+234${phoneNumber.slice(1)}`;
            setConvertedNumber(converted);
        }
        setNameState({ ...nameState, ...{ phoneNumber: phoneNumber } });
    };
    const handleEmail = (e) => {
        const value = e.target.value;
        setemail(value);
        setNameState({ ...nameState, ...{ email } });
    };
    const handleState = (e) => {
        const value = e.target.value;
        setstate(value);
        setNameState({ ...nameState, ...{ state } });
    };
    const handleAddress1 = (e) => {
        const value = e.target.value;
        setaddress(value);
        setNameState({ ...nameState, ...{ address } });
    };
    const handlePassword = (e) => {
        const value = e.target.value;
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(`${consts.pub_key}`);
        var encrypted = encrypt.encrypt(value);
        setPassword(encrypted);
        setNameState({ ...nameState, ...{ password: value } });
    };

    const togglemodal = ()=>{
        setshowerror(!showerror)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            await personal(nameState, ()=>{ 
            next();
            // setPending(true);
            }, ()=>{ 
                setErrorHandler(error)
                setshowerror(true)
                // setPending(false);
            });
        }catch(error){
            // setPending(false);
        }
    };



    return ( 
        <form onSubmit={handleSubmit} method="POST">
            <div className={styles.form2}>
                <div className={styles2.field}>
                    <label className={styles2.fieldlabel}>What does Text Venture do ? *</label>
                    <textarea 
                        className={styles2.fieldtext}
                        placeholder="Enter text"
                        onBlur={handleDescription}
                        onChange={handleDescription}
                        required
                    >
                    </textarea>
                </div>
                <p className='formtext'>You are advise to write a detailed text about your business for example Fresh Foods is a local grocery store committed to providing high-quality, locally-sourced produce, meats, and specialty products. Conveniently located downtown with competitive pricing.</p>
            </div>
            <div className={styles.form2}>
                <div className={styles2.field}>
                    <label className={styles2.fieldlabel}>Phone</label>
                    <input 
                        className={styles2.fieldinput}
                        type="text"
                        placeholder="Enter phone number"
                        
                        onBlur={handleNumber}
                        onChange={handleNumber}
                        required
                    >
                    </input>
                </div>
            </div>
            <div className={styles.form2}>
                <div className={styles2.field}>
                    <label className={styles2.fieldlabel}>Business Email</label>
                    <input 
                        className={styles2.fieldinput}
                        type="email"
                        placeholder="Enter Email" 
                        onBlur={handleEmail}
                        onChange={handleEmail}
                        required
                    >
                    </input>
                </div>
            </div>
            <div className={styles.form2}>
                <div className={styles2.field}>
                    <label className={styles2.fieldlabel}>Where is your business located *</label>
                    <select 
                        className={styles2.fieldinput}
                        onBlur={handleState}
                        onChange={handleState}
                        required
                    >
                        <optgroup>
                            <option disabled selected>--Select State--</option>
                                <option value="Abia">Abia</option>
                                <option value="Adamawa">Adamawa</option>
                                <option value="Akwa Ibom">Akwa Ibom</option>
                                <option value="Anambra">Anambra</option>
                                <option value="Bauchi">Bauchi</option>
                                <option value="Bayelsa">Bayelsa</option>
                                <option value="Benue">Benue</option>
                                <option value="Borno">Borno</option>
                                <option value="Cross River">Cross River</option>
                                <option value="Delta">Delta</option>
                                <option value="Ebonyi">Ebonyi</option>
                                <option value="Edo">Edo</option>
                                <option value="Ekiti">Ekiti</option>
                                <option value="Enugu">Enugu</option>
                                <option value="FCT">Federal Capital Territory</option>
                                <option value="Gombe">Gombe</option>
                                <option value="Imo">Imo</option>
                                <option value="Jigawa">Jigawa</option>
                                <option value="Kaduna">Kaduna</option>
                                <option value="Kano">Kano</option>
                                <option value="Katsina">Katsina</option>
                                <option value="Kebbi">Kebbi</option>
                                <option value="Kogi">Kogi</option>
                                <option value="Kwara">Kwara</option>
                                <option value="Lagos">Lagos</option>
                                <option value="Nasarawa">Nasarawa</option>
                                <option value="Niger">Niger</option>
                                <option value="Ogun">Ogun</option>
                                <option value="Ondo">Ondo</option>
                                <option value="Osun">Osun</option>
                                <option value="Oyo">Oyo</option>
                                <option value="Plateau">Plateau</option>
                                <option value="Rivers">Rivers</option>
                                <option value="Sokoto">Sokoto</option>
                                <option value="Taraba">Taraba</option>
                                <option value="Yobe">Yobe</option>
                                <option value="Zamfara">Zamfara</option>
                        </optgroup>
                    </select>
                </div>
            </div>
            <div className={styles.form2}>
                <div className={styles2.field}>
                    <label className={styles2.fieldlabel}>Address</label>
                    <input 
                        className={styles2.fieldinput}
                        type="text"
                        placeholder="Address Line 1"
                        onBlur={handleAddress1}
                        onChange={handleAddress1}
                        required
                    >
                    </input>
                </div>
            </div>
            <div className={styles.form2}>
                <div className={styles2.field}>
                    <label className={styles2.fieldlabel}>Password</label>
                    <input 
                        className={styles2.fieldinput}
                        type="password"
                        placeholder="***********"
                        onBlur={handlePassword}
                        onChange={handlePassword}
                        required
                    >
                    </input>
                </div>
            </div>
           
            <div className="signup-button">
                {loading ? (
                    <button className="btn btn-primary shadow-2 mb-4 text-center submit-button" disabled>
                        <div className="animation">
                            <LottieAnimation data={loader}/>
                        </div>
                    </button>
                ) : (
                    <button className="btn btn-primary shadow-2 mb-4 text-center submit-button"><span>Save</span></button>
                )}         
            </div>
            <p className="mb-0 text-muted">I Already Hava an Account? <Link to="/">Signin</Link></p>
            {showerror && (<Errormodal error={error} togglemodal={togglemodal}/>)}
        </form>
    );
}


const mapStoreToProps = (state) => {
    return {
        error: state.personal.error,
        loading: state.personal.loading
    };
};
  
const mapDispatchToProps = (dispatch) => {
    return {
        personal: (nameState, history, setErrorHandler) => {
            dispatch(postpersonal(nameState, history, setErrorHandler));
        }
    };
};

export default connect(mapStoreToProps, mapDispatchToProps)(Personal);